import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

import {
  BreadcrumbModule,
  ColorModeService,
  ContainerComponent,
  DropdownComponent,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  NavItemComponent,
  NavLinkDirective,
  SidebarToggleDirective,
  ThemeDirective,
  ToasterComponent,
  ToasterPlacement
} from '@coreui/angular-pro';

import { IconDirective } from '@coreui/icons-angular';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/authentication/authentication.service';
import { AppState } from 'src/app/store/app.state';
import { MessageType } from 'src/app/store/payment/models/payroll.model';
import { ToastAlertComponent } from 'src/app/views/components/ow-toast-alert/ow-toast-alert.component';
import { NotificationMessage } from 'src/app/store/ui/models/model';
import * as FeatureFlagActions from 'src/app/store/ui/actions/feature-flags.actions'

@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html',
  standalone: true,
  imports: [ContainerComponent, HeaderTogglerDirective, SidebarToggleDirective, IconDirective, HeaderNavComponent, NavItemComponent, NavLinkDirective, RouterLink, RouterLinkActive, NgTemplateOutlet, BreadcrumbModule, ThemeDirective, DropdownComponent, DropdownToggleDirective, DropdownMenuDirective, DropdownItemDirective, DropdownHeaderDirective, ToasterComponent]
})
export class SecureHeaderComponent extends HeaderComponent implements OnInit {

  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;

  readonly colorModes = [
    { name: 'light', text: 'Light', icon: 'cilSun' },
    { name: 'dark', text: 'Dark', icon: 'cilMoon' },
    { name: 'auto', text: 'Auto', icon: 'cilContrast' }
  ];

  readonly icons = computed(() => {
    const currentMode = this.colorMode();
    return this.colorModes.find(mode => mode.name === currentMode)?.icon ?? 'cilSun';
  });

  sidebarId = input('sidebar1');

  @ViewChild(ToasterComponent) toaster!: ToasterComponent;

  userRoles!: string[];

  constructor(private router: Router, private authService: AuthService, private amplifyService: AuthenticatorService, private store: Store<AppState>) {
    super();
  }

  async ngOnInit() {
    const currentUser = await this.authService.getCurrentUser();
    this.userRoles = currentUser?.roles ?? []

    // Subscribe to notifications for whole application
    const notification$ = this.store.select(state => state.ui.notification);
    notification$.subscribe(
      {
        next: (notificationMessage) => {
          if (notificationMessage) {
            this.addToast(notificationMessage)
          }
        },
        error: err => {
          console.log(err);
          const notification = new NotificationMessage()
          notification.message = 'Something went wrong! Please try again!'
          notification.messageType = MessageType.Error;
          this.addToast(notification);
        }
      }
    )

    // Start loading feature flags
    const organizationId = await this.authService.getOrganizationId();
    const clientIds = await this.authService.getAllowedClients()
    this.store.dispatch(FeatureFlagActions.loadFeatureFlags({ organizationId, clientIds }))
  }

  addToast(notification: NotificationMessage) {
    const options = {
      // title: `CoreUI for Angular Toast`,
      message: notification.message,
      delay: 8000,
      placement: ToasterPlacement.TopEnd,
      color: notification.messageType,
      autohide: true,
    }
    const componentRef = this.toaster.addToast(ToastAlertComponent, { ...options });
  }


  logOut() {
    this.amplifyService.signOut()
    this.router.navigate(['/']);
  }



}
